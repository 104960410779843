import React, { useEffect, useState } from "react";
import TitleBar from "../TitleBar";
import { GrDocumentPerformance } from "react-icons/gr";
import formatAmount from "../../custom/FormatAmount";
import KpiCard from "./KpiCard";
import ItemCharts from "./ItemCharts";
import useGetApi from "../Hooks/useGetApi";
import EstimateData from "./EstimateData";
import { CircularProgress } from "@mui/material";
import Monthlies from "./Monthlies";
import SrCard from "./SrCard";
import CostTracking from "./CostTracking";

const KpisDBoard = () => {
  const { getData } = useGetApi();
  const [cardData, setcardData] = useState({});
  const [loading, setLoading] = useState(true);

  const getReportData = () => {
    getData(
      `/KPI/GetAgentEstimateMonthlyReport?AgentId=3251&Month=11&Year=2024`,
      (data) => {
        console.log("GetAgentEstimateMonthlyReport", data);
        setcardData(data);
        setLoading(false);
      },
      (err) => {
        console.log("GetAgentEstimateMonthlyReport errr", err);
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    getReportData();
  }, []);

  if (loading) {
    return (
      <div className="center-loader">
        <CircularProgress style={{ color: "#789a3d" }} />
      </div>
    );
  }

  return (
    <div>
      <TitleBar
        icon={<GrDocumentPerformance size={"20px"} />}
        title={"KPIs DashBoard"}
      ></TitleBar>
      <div className="container-fluid">
        <EstimateData data={cardData} />
        <div className="mt-3">
          <SrCard data={cardData} />
        </div>
        {/* <h3>Estimates</h3> */}
        {/* <div className="row mt-3">
          <KpiCard
            color={"primary"}
            title={"Submitted"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
            amount={cardData.NoOfEstimateSubmitted}
          />
          <KpiCard
            color={"secondary"}
            title={"Approved"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={cardData.NoOfEstimateApproved}
          />
          <KpiCard
            color={"info"}
            title={"Amount Submitted"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={cardData.AmountSubmitted}
          />

          <KpiCard
            color={"light"}
            title={"Amount Approved"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={cardData.AmountApproved}
          />

          <KpiCard
            color={"dark"}
            title={"Close %"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={`${cardData.ClosePercentage?.toFixed(2)}%`}
             isString
          />

          <KpiCard
            color={"danger"}
            title={"Avg Time to close"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={`${cardData.AvgTimeToClose?.toFixed(2)} days`}
             isString
          />

          <KpiCard
            color={"success"}
            title={"Avg # Days Spent in Approved"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={cardData.NoOfEstimateSubmitted}
          />
          <KpiCard
            color={"success"}
            title={"Avg # Days Spent in Ready to Invoice"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={cardData.NoOfEstimateSubmitted}
          />
        </div> 
        <h3>Service Requests</h3>
        <div className="row mt-3">
          <KpiCard
            color={"primary"}
            title={"# of Service Requests "}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={cardData.NoOfServiceRequest}
             isString
          />
          <KpiCard
            color={"secondary"}
            title={"Avg time to closed"}
            icon={<GrDocumentPerformance size={"20px"} color="white" />}
             amount={`${cardData.AvgTimeToCloseSR?.toFixed(2)} days`}
             isString
          />
        
        </div>*/}
        <div className="row mt-3">
          <div className="col-md-5 h-100">
            <CostTracking />
          </div>
          <div className="col-md-7  h-100">
            <Monthlies />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mt-3">
            <ItemCharts />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpisDBoard;

import React from 'react'
import TitleBar from '../TitleBar'
import ServiceRequests from './ServiceRequests'

const SRlist = () => {

 

    return (
        <>
           
            <ServiceRequests />
        </>
    )
}

export default SRlist
import React from "react";
import { GrDocumentPerformance } from "react-icons/gr";
import formatAmount from "../../custom/FormatAmount";
const EstimateData = ({data}) => {
  return (
    <div className="estimatedata-kpi-bg p-3">
      <h1>Estimates</h1>
      <div className="row mt-3">
        <div className="col-md-3 p-5 py-2">
          <div className="border-end border-white border-1 d-md-block border-end-md">
            <div className="d-flex align-items-center mb-2 ">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">{data.NoOfEstimateSubmitted}</h4>
                <p>Submitted</p>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                <p>Approved</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-5 py-2 ps-2">
          <div className="border-end border-white border-1 d-md-block border-end-md">
            <div className="d-flex align-items-center mb-2 ">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">${formatAmount(data.AmountSubmitted)}</h4>
                <p>Amount Submitted</p>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">${formatAmount(data.AmountApproved)}</h4>
                <p>Amount Approved</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-5 py-2 ps-2">
          <div className="border-end border-white border-1 d-md-block border-end-md">
            <div className="d-flex align-items-center mb-2 ">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">{data.ClosePercentage.toFixed(2)}</h4>
                <p>Close %</p>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">{data.AvgTimeToClose.toFixed(2)}</h4>
                <p>Avg Time to close</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-5 py-2 ps-0">
          <div className="">
            <div className="d-flex align-items-center mb-2">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">{data.AvgDaysInApprove.toFixed(2)}</h4>
                <p>Avg # Days Spent in Approved</p>
              </div>
            </div>
            <div className="d-flex align-items-center mt-3">
              <GrDocumentPerformance color="white" size={"30px"} />
              <div className="ms-3">
                <h4 className="mb-0">{data.AvgDaysInReadyToInvoice.toFixed(2)}</h4>
                <p className="">Avg # Days in Ready to Invoice</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimateData;

import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Monthlies = () => {
    const data = [

         
            { month: "January", total: 74167 },
            { month: "February", total: 74266 },
            { month: "March", total: 117570 },
            { month: "April", total: 169584},
            { month: "May", total: 132290},
            { month: "June", total: 111086 },
            { month: "July", total: 108164 },
            { month: "August", total: 121781 },
            { month: "September", total: 123576},
            { month: "October", total: 150769 },
            { month: "November", total: 117628 },
            { month: "December", total: 117628 },
        
      ];
      const chartOptions = {
        title: {
          text: 'Monthly Invoice Totals',
        },
        xAxis: {
          categories: data.map((item) => item.month),
          title: {
            text: 'Months',
          },
        },
        yAxis: {
          title: {
            text: 'Total',
          },
        },
        series: [
          {
            name: 'Total',
            data: data.map((item) => item.total),
          },
        ],
        chart: {
          type: 'line',
        },
      };
  return (
    <div>
         <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  )
}

export default Monthlies
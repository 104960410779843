import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import formatAmount from '../../custom/FormatAmount';

const ItemCharts = () => {
  const data = [
    {
      title: "Irrigation Material",
      data: [
        { month: "January", total: 74167, expense: 49099, percentage: "33.80%" },
        { month: "February", total: 74266, expense: 29493, percentage: "60.29%" },
        { month: "March", total: 117570, expense: 56666, percentage: "51.80%" },
        { month: "April", total: 169584, expense: 71760, percentage: "57.68%" },
        { month: "May", total: 132290, expense: 56749, percentage: "57.10%" },
        { month: "June", total: 111086, expense: 65189, percentage: "41.32%" },
        { month: "July", total: 108164, expense: 54118, percentage: "49.97%" },
        { month: "August", total: 121781, expense: 72424, percentage: "40.53%" },
        { month: "September", total: 123576, expense: 106860, percentage: "13.53%" },
        { month: "October", total: 150769, expense: 74182, percentage: "50.80%" },
        { month: "November", total: 117628, expense: 63714, percentage: "45.83%" },
        { month: "December", total: 117628, expense: 63714, percentage: "45.83%" },
      ],
    },
    {
      title: "Plant Material",
      data: [
        { month: "January", total: 61361, expense: 42705, percentage: "30.40%" },
        { month: "February", total: 95489, expense: 43169, percentage: "54.79%" },
        { month: "March", total: 164014, expense: 44114, percentage: "73.10%" },
        { month: "April", total: 157174, expense: 81961, percentage: "47.85%" },
        { month: "May", total: 192983, expense: 58363, percentage: "69.76%" },
        { month: "June", total: 170401, expense: 40719, percentage: "76.10%" },
        { month: "July", total: 158724, expense: 34438, percentage: "78.30%" },
        { month: "August", total: 127803, expense: 55802, percentage: "56.34%" },
        { month: "September", total: 91332, expense: 60507, percentage: "33.75%" },
        { month: "October", total: 117628, expense: 63714, percentage: "45.83%" },
        { month: "November", total: 117628, expense: 63714, percentage: "45.83%" },
        { month: "December", total: 117628, expense: 63714, percentage: "45.83%" },
      ],
    },
    {
      title: "Mulch Material",
      data: [
        { month: "January", total: 74167, expense: 49099, percentage: "33.80%" },
        { month: "February", total: 74266, expense: 29493, percentage: "60.29%" },
        { month: "March", total: 117570, expense: 56666, percentage: "51.80%" },
        { month: "April", total: 169584, expense: 71760, percentage: "57.68%" },
        { month: "May", total: 132290, expense: 56749, percentage: "57.10%" },
        { month: "June", total: 111086, expense: 65189, percentage: "41.32%" },
        { month: "July", total: 108164, expense: 54118, percentage: "49.97%" },
        { month: "August", total: 121781, expense: 72424, percentage: "40.53%" },
        { month: "September", total: 123576, expense: 106860, percentage: "13.53%" },
        { month: "October", total: 150769, expense: 74182, percentage: "50.80%" },
        { month: "November", total: 127628, expense: 14714, percentage: "45.83%" },
        { month: "December", total: 117628, expense: 63714, percentage: "45.83%" },
      ],
    },
    {
      title: "General Material",
      data: [
        { month: "January", total: 61361, expense: 42705, percentage: "30.40%" },
        { month: "February", total: 95489, expense: 43169, percentage: "54.79%" },
        { month: "March", total: 164014, expense: 44114, percentage: "73.10%" },
        { month: "April", total: 157174, expense: 81961, percentage: "47.85%" },
        { month: "May", total: 192983, expense: 58363, percentage: "69.76%" },
        { month: "June", total: 170401, expense: 40719, percentage: "76.10%" },
        { month: "July", total: 158724, expense: 34438, percentage: "78.30%" },
        { month: "August", total: 127803, expense: 55802, percentage: "56.34%" },
        { month: "September", total: 91332, expense: 60507, percentage: "33.75%" },
        { month: "October", total: 117628, expense: 63714, percentage: "45.83%" },
        { month: "November", total: 117628, expense: 63714, percentage: "45.83%" },
        { month: "December", total: 117628, expense: 63714, percentage: "45.83%" },
      ],
    },
  ];

  const categories = data[0].data.map((item) => item.month); // Months on the x-axis

  const series = data.map((item) => ({
    name: item.title, // Title of the dataset
    data: item.data.map((entry) => entry.total), // Totals for each month
  }));

  const options = {
    chart: {
      type: 'column', // Vertical bars
    },
    title: {
      text: 'Material and Expense Data',
    },
    xAxis: {
      categories: categories, // Months as categories
      title: {
        text: 'Months',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Amount (USD)',
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        groupPadding: 0.1, // Adjust space between groups
      },
    },
    tooltip: {
      formatter: function () {
        // `this` refers to the hovered point
        const dataIndex = this.point.index; // Get the index of the current point
        const datasetIndex = this.series.index; // Get the series index
        const dataItem = data[datasetIndex].data[dataIndex]; // Find the corresponding data item
  
        return `
          <b>${this.series.name}</b><br>
          <b>Total:</b> ${formatAmount(this.point.y)}<br>
          <b>Expense:</b> ${formatAmount(dataItem.expense)}<br>
          <b>Percentage:</b> ${dataItem.percentage}
        `;
      },
      useHTML: true, // Allows HTML in tooltip content
    },
    series: series, // Pass the series data dynamically
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ItemCharts;

import React, { useEffect, useState, useContext } from "react";
import TitleBar from "../../../TitleBar";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  TextField,
  TablePagination,
  TableSortLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import TblDateFormat from "../../../../custom/TblDateFormat";
import CircularProgress from "@mui/material/CircularProgress";
import { DataContext } from "../../../../context/AppData";
import StatusCards from "../../../Landscape/StatusCards";
import AddButton from "../../../Reusable/AddButton";
import { baseUrl } from "../../../../apiConfig";
import ArrowOutwardIcon from "@mui/icons-material/OpenInNew";
import DeleteModal from "../../../CommonComponents/DeleteModal";

const RisingCaneTable = () => {
  const icon = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5096 2.53165H7.41104C5.50437 2.52432 3.94146 4.04415 3.89654 5.9499V15.7701C3.85437 17.7071 5.38979 19.3121 7.32671 19.3552C7.35512 19.3552 7.38262 19.3561 7.41104 19.3552H14.7343C16.6538 19.2773 18.1663 17.6915 18.1525 15.7701V7.36798L13.5096 2.53165Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2688 2.52084V5.18742C13.2688 6.48909 14.3211 7.54417 15.6228 7.54784H18.1482"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0974 14.0786H8.1474"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2229 10.6388H8.14655"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const token = Cookies.get("token");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const navigate = useNavigate();
  const { loggedInUser } = useContext(DataContext);
  const [statusId, setStatusId] = useState(0);
  const [records, setRecords] = useState({});

  const [weeklyReport, setWeeklyReport] = useState(true);
  const [WeeklyReportData, setWeeklyReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchWeeklyReports = async () => {
    try {
      const res = await axios.get(
        `${baseUrl}/api/WeeklyReport/GetWeeklyReportRCList`,
        { headers }
      );
      res.data.sort((a, b) => b.WeeklyReportRCId - a.WeeklyReportRCId);
      setWeeklyReportData(res.data);
      setLoading(false);
      setRecords({
        open: res.data.filter((report) => report.StatusId === 1).length,
        closed: res.data.filter((report) => report.StatusId === 2).length,
      });

      setWeeklyReport(false);
      console.log("rising cane report data is", res.data);
    } catch (error) {
      console.log("report api call error", error);
      setWeeklyReport(true);
      setLoading(false);
    }
  };

  const [filteredWeeklyReportData, setFilteredWeeklyReportData] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    let filteredReports =
      statusId === 0
        ? WeeklyReportData
        : WeeklyReportData.filter((report) => report.StatusId === statusId);
    setFilteredWeeklyReportData(filteredReports);
  }, [statusId, WeeklyReportData]);

  let filteredReports = WeeklyReportData
  .filter((report) => statusId === 0 || report.StatusId === statusId)
  .filter((report) => 
    report.RegionalManagerName?.toLowerCase().includes(search.toLowerCase()) || 
    report.ContactCompany?.toLowerCase().includes(search.toLowerCase()) || 
    report.ContactName?.toLowerCase().includes(search.toLowerCase()) || 
    report.DisplayName?.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    fetchWeeklyReports();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);


  // Handle change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TitleBar icon={icon} title="Monthly Report- Raising Canes" />
      <div className="container-fluid">
        <div className="row">
          {" "}
          <StatusCards
            setStatusId={setStatusId}
            statusId={statusId}
            records={records}
          />
        </div>
        <div className="card">
          <div className="row mx-2 mt-2 mb-2">
          <div className="col-md-3">
            <TextField
              label="Search Report"
              variant="standard"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
            <div className="col-md-9 text-end">
              {/* {loggedInUser.userRole == "1" && ( */}
                <AddButton
                  onClick={() => {
                    navigate(`/Weekly-Reports/add-rising-canes`);
                  }}
                >
                  Add monthly report - RC
                </AddButton>
              {/* )} */}
            </div>
          </div>

          <div className="card-body pt-0">
            {loading ? (
              <div className="center-loader">
                <CircularProgress />
              </div>
            ) : (
              <TableContainer sx={{ overflowX: "auto" }}>
                <Table hover>
                  <TableHead className="table-header">
                    <TableRow className=" bill-tbl-alignment">
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Contact</TableCell>
                      <TableCell>Regional Manager</TableCell>

                      <TableCell>Contact Company</TableCell>
                      <TableCell>Report Month</TableCell>
                      <TableCell>Status</TableCell>
                      {loggedInUser.userRole == 1 && (
                    <TableCell align="right">Actions</TableCell>
                  )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {weeklyReport ? (
                      <TableRow>
                        <TableCell className="text-center" colSpan={12}>
                          No Record Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredReports
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((report) => (
                          <TableRow
                            key={report.WeeklyReportRCId}
                            className="bill-tbl-alignment"
                            hover
                          >
                            <TableCell>
                              {report.DisplayName}
                              <NavLink
                                to={`/customers/add-customer?id=${report.CustomerId}`}
                                target="_blank"
                              >
                                <ArrowOutwardIcon style={{ fontSize: 14 }} />
                              </NavLink>
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                navigate(
                                  `/Weekly-Reports/add-rising-canes?id=${report.WeeklyReportRCId}`
                                );
                              }}
                            >
                              {report.ContactName}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                navigate(
                                  `/Weekly-Reports/add-rising-canes?id=${report.WeeklyReportRCId}`
                                );
                              }}
                            >
                              {report.RegionalManagerName}
                            </TableCell>

                            <TableCell
                              onClick={() => {
                                navigate(
                                  `/Weekly-Reports/add-rising-canes?id=${report.WeeklyReportRCId}`
                                );
                              }}
                            >
                              {report.ContactCompany}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                navigate(
                                  `/Weekly-Reports/add-rising-canes?id=${report.WeeklyReportRCId}`
                                );
                              }}
                            >
                              {(report.ReportForWeekOf)}
                            </TableCell>

                            <TableCell>
                              <span
                                style={{
                                  backgroundColor: report.ReportStatusColor,
                                }}
                                onClick={() => {
                                  navigate(
                                    `/Weekly-Reports/rising-canes-preview?id=${report.WeeklyReportRCId}`
                                  );
                                }}
                                className="span-hover-pointer badge badge-pill  "
                              >
                                {report.ReportStatus}
                              </span>
                            </TableCell>
                            {loggedInUser.userRole == 1 && (
                        <TableCell align="right">
                          <DeleteModal
                            endPoint={`WeeklyReport/DeleteWeeklyReportRC`}
                            successFun={fetchWeeklyReports}
                            deleteId={report.WeeklyReportRCId}
                          />
                        </TableCell>
                      )}
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <TablePagination
              component="div"
              count={weeklyReport ? 0 : filteredWeeklyReportData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RisingCaneTable;

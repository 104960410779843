import React from "react";
import { GrDocumentPerformance } from "react-icons/gr";
import formatAmount from "../../custom/FormatAmount";

const SrCard = ({ data }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="sr-kpi-bg p-3 h-100">
          <h1>Service Requests</h1>
          <div className="row mt-3">
            <div className="col-md-12 p-5 py-2">
              <div className="">
                <div className="d-flex align-items-center mb-2 ">
                  <GrDocumentPerformance color="white" size={"30px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateSubmitted}</h4>
                    <p># of Service Requests</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <GrDocumentPerformance color="white" size={"30px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                    <p>Avg time to closed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="mantainess-kpi-bg p-3">
          <h3>Maintainess</h3>
          <div className="row mt-3">
            <div className="col-md-12 p-5 py-2">
              <div className=" ">
                <div className="d-flex align-items-center mb-2 ">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateSubmitted}</h4>
                    <p>Jobs Submitted</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                    <p>Jobs Approved</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                    <p>Amount</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                    <p>Close %</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="non-mantainess-kpi-bg p-3">
          <h3>Non Maintainess</h3>
          <div className="row mt-3">
            <div className="col-md-12 p-5 py-2">
              <div className=" ">
                <div className="d-flex align-items-center mb-2 ">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateSubmitted}</h4>
                    <p>Jobs Submitted</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                    <p>Jobs Approved</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                    <p>Amount</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <GrDocumentPerformance color="white" size={"25px"} />
                  <div className="ms-3">
                    <h4 className="mb-0">{data.NoOfEstimateApproved}</h4>
                    <p>Close %</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SrCard;

import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CostTracking = () => {
  const data = [
    { title: "Job Cost", total: 74167 },
    { title: "Truck Cost", total: 74266 },
    { title: "Incident Cost", total: 117570 },
    { title: "Uniform Cost", total: 27570 },
    { title: "Office Cost", total: 47570 },
  ];

  const chartOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Cost Tracking",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b> <br/> Percentage: <b>{point.percentage:.1f}</b> %",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        size: "55%",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [
      {
        name: "Cost Share",
        colorByPoint: true,
        data: data.map((item) => ({
          name: item.title,
          y: item.total,
        })),
      },
    ],
  };

  return (
    <div className="card">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default CostTracking;
